/* src/app/terms/dialog/terms-dialog.component.scss */
.dialog-header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.dialog-content .content {
  padding: 0 1.5rem 1.5rem;
  height: auto;
  transition: height 1s ease-in-out;
}
.dialog-content .content.hide {
  height: 0;
}
.dialog-content .content ::ng-deep .mat-mdc-checkbox .mdc-form-field {
  align-items: flex-start;
}
.dialog-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  padding: 1rem;
}
.dialog-actions .mdc-button {
  min-width: 8rem;
}
:host(.terms) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
}
/*# sourceMappingURL=terms-dialog.component.css.map */
