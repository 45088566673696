/* src/app/terms/terms-conditions/terms-conditions.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.terms-conditions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2em 3em;
}
.terms-conditions .sub-header {
  font-size: 14px;
  color: #ffb500;
}
.terms-conditions .terms-content {
  padding: 1em 2em;
}
.terms-conditions .terms-content.content {
  height: 50vh;
  width: 100%;
  overflow: auto;
}
@media screen and (min-width: 48rem) {
  .terms-conditions .terms-content.content {
    height: 35vh;
  }
}
.terms-conditions .content {
  border: 1px solid;
}
.page-actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3rem 2rem;
}
.page-actions .page-action {
  margin-left: 1rem;
}
.mat-mdc-button {
  color: #4C91E2;
}
.page-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f0f0ef;
  padding: 2rem 0px;
  overflow: hidden;
  border-top: solid 2px #004791;
  border-bottom: none;
}
#page-content-wrapper {
  color: #242424;
  padding-left: 0px !important;
}
#page-content-wrapper .header .header-items {
  max-width: 109em !important;
  height: 7rem !important;
}
.header {
  min-width: 27em;
  background-color: #f9f9f9;
}
.header .header-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 6rem;
  padding: 1rem 2rem;
  min-width: 23.438rem;
  max-width: 75rem;
}
.header .header-items .header-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
}
.header .header-items .header-item:last-of-type {
  margin-left: auto;
}
.header .header-items .header-item.logo-container {
  max-width: 50%;
  min-width: 208px;
}
@media screen and (min-width: 48rem) {
  .header .header-items .header-item.logo-container {
    max-width: 20%;
  }
}
/*# sourceMappingURL=terms-conditions.component.css.map */
